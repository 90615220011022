body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ol-control button {
  font-size: 1.5rem !important;
  width: 2rem !important;
  height: 2rem !important;
}

/* .ol-control button:hover {
  border: none !important;
  outline: none !important;
}

.ol-control button:focus {
  border: none !important;
  outline: none !important;
}
.ol-control button:active {
  border: none !important;
  outline: none !important;
}
.ol-control button:focus-within {
  border: none !important;
  outline: none !important;
} */
 
.ol-geocoder{
  left:4px !important;
  top:3rem !important;
}
.ucontrol-fullscreen {
  top: 4px;
  right: 4px;
}

.ucontrol-zoom {
  top: 42px;
  right: 4px;
}

.ucontrol-mouseposition {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: #f2f2f2;
  color: black;
  padding: 4px;
  min-width: 140px;
  text-align: center;
  border-radius: 3px;
}

.ucontrol-baselayer {
  /* top: 113px;
  right: 4px; */
  bottom: 4px;
  left: 4px;
}

.ucontrol-goto {
  top: 113px;
  right: 4px;
}

.ol-scale-bar {
  left: 50% !important;
  transform: translateX(-50%);
}

.custom-control {
  background-color: white !important;
}

.ucontrol-print {
  top: 130px;
  right: 4px;
}

.ucontrol-wmslayers {
  top: 4px;
  left: 4px;
}

.ol-attribution.ol-uncollapsible {
  display: none;
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

.no-border-button {
  border: none !important;
  outline: none;
}
.no-border-button:hover {
  border: none !important;
  text-decoration: none;
  outline: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color) !important;
}

/* .no-border-button:focus {
  border: none !important;
  outline: none !important;
}
.no-border-button:focus-within {
  border: none !important;
  outline: none !important;
} */

.help-pre {
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid gray;
  font-weight: 700;
}
.help {
  display: block;
  overflow: hidden;
}
.help .help-title {
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
}
.help .help-body {
  margin: 0 20px 20px;
  line-height: 18px;
  word-wrap: break-word;
  overflow: hidden;
  text-align: left;
}

.sider-toolbox .ant-layout-sider-trigger {
  background-color: #00aeef;
}

.ant-collapse-content-box {
  padding: 0px !important;
}
.custom-collapse .ant-collapse-header {
  font-size: 13px;
  padding: 6px 16px !important;
  font-weight: 500;
}
.custom-collapse .ant-collapse-expand-icon {
  height: 20px !important;
}
.ant-table-row:nth-child(odd) {
  background-color: #ffffff;
}

.ant-table-row:nth-child(even) {
  background-color: #f9f9f9;
}
.custom-table .ant-table-thead > tr > th {
  padding: 8px;
  font-size: 12px;
}

.custom-table .ant-table-tbody > tr > td {
  padding: 8px;
  font-size: 12px;
}

.custom-table .ant-table {
  font-size: 12px;
}
